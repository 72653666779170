export const REACT_APP_SERVER = process.env.REACT_APP_SERVER;
export const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;
export const REACT_APP_LOCAL_HOST = process.env.REACT_APP_LOCAL_HOST;
export const DEV_ENVIRONMENT = process.env.REACT_APP_DEV_ENVIRONMENT;
export const ERROR_MESSAGE = 'Unable to connect to server.';
export const FILE_UPLOAD_MESSAGE = 'File/Directory uploaded successfuly';
export const FILE_RENAME_MESSAGE = 'File/Directory renamed successfuly';
export const DIR_CREATED_MESSAGE = 'Directory created successfuly';
export var API_ACCESS_TOKEN = '';

export const AUTH_CONFIG = {
  LOGIN_TYPE_GOOGLE: 'goog',
  LOGIN_TYPE_MICROSOFT: 'msft',
  LOGIN_TYPE_ADMIN: `admin`,
  LOGIN_TYPE_USER: `user`,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID
};

export const ROLES = {
  SUPER_ADMIN: 'super-admin',
  ADMIN_OPERATOR: 'admin-operator',
  END_USER: 'end-user',
  TENANT_OPERATOR: 'tenant-operator',
  TENANT_ADMIN: 'tenant-admin'
};

export const USER_TYPE = {
  TENANT_USER: 't-u',
  ADMIN_USER: 'a-u'
};

export const ADMIN_ROLES = [
  {
    roleName: 'Super Admin',
    id: 'super-admin'
  },
  {
    roleName: 'Admin Operator',
    id: 'admin-operator'
  }
];

export const TENANT_ROLES = [
  {
    roleName: 'Tenant Admin',
    id: 'tenant-admin'
  },
  {
    roleName: 'Tenant Operator',
    id: 'tenant-operator'
  },
  {
    roleName: 'End User',
    id: 'end-user'
  }
];

export const getRole = (roleId) => {
  const roleList = [...ADMIN_ROLES, ...TENANT_ROLES];
  const filter = roleList.filter((role) => {
    return roleId === role.id;
  });
  if (filter.length > 0) {
    return filter[0].roleName;
  }
};

export const ACCESS_TOKEN = `ACCESS_TOKEN `;
export const CHAT_BOT_API_TOKEN = `chatBotApiToken`;

export const LIST_VIEW = {
  TABLE_VIEW: 'Table',
  CARD_VIEW: 'Card'
};

export const ASSET_TYPE = {
  SSH: 'SSH'
};
export const USER_TABLE = 'UserTable';

export const I18N = {
  DEFAULT: 'lang',
  ADMIN_USER: 'adminusermanagement',
  ORGANIZATION: 'organization',
  USER_MANAGEMENT: 'usermanagement',
  SIDE_BAR_MENU: 'sidebarmenu',
  SECRET_MANAGEMENT: 'secretmanagement',
  SSH_SERVER_MANAGEMENT: 'sshservermanagement',
  CHAT_BOT: 'chatbot',
  FILE_MANAGER: 'filemanager'
};

export const FILE_MANAGER = {
  USER_HOME_DIR: '~',
  ROOT_DIR: '/',
  FILE_VIEW: 'FV',
  TREE_VIEW: 'TV',
  BREACRUMB: 'BREACRUMB',
  HOME: 'home',
  ROOT: 'root',
  FILE: 'f',
  DIR: 'd'
};

export const SOURCE_BOT_TYPE = [
  { id: 'website', sourceType: 'website', botType: 'Web url' },
  { id: 's3', sourceType: 's3', botType: 'S3 Bucket' },
  { id: 'database', sourceType: 'database', botType: 'Database' }
];
export const REGEX = {
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NUMBER_DECIMAL:
    /^(?:\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?|0(?:\.\d{1,2})?)$/,
  NUMBER_INTEGER: /^(?:\d*[1-9]\d*|)$/,
  TEXT_ONLY: /^[a-zA-Z ]*$/,
  PASSWORD:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?!.*\s).{8,}$/,
  ALPHA_NUMBERICS: /^[a-zA-Z0-9-_ ]*$/,
  LINUX_USER: /^[a-z][-a-z0-9._]*$/,
  DNS_OR_IP: /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9]))*$/,
  JSON: /^[\],:{}\s]*$|^"(.|\\[\\"/bfnrt])*"$/,
  URL: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})$/
};

export const FIELD_TYPE = {
  TEXT: 'text',
  RADIO: 'radio',
  EMAIL: 'email',
  SWITCH: 'switch',
  SELECT: 'select',
  REGEX: 'regex',
  FREE_TEXT: 'freetext',
  PASSWORD: 'password',
  CHECKBOX: 'checkbox',
  TEXTAREA: 'textarea',
  NUMBER_ONLY: 'number_only',
  INTEGER_ONLY: 'integer_only',
  ALPHA_NUMBERICS: 'alphanumerics',
  MULTI_SELECT: 'multi-select',
  AUTOCOMPLETE: 'autocomplete',
  CHECKBOX_GROUP: 'checkbox_group'
};

//below const is from seed, we can remove it if it is not breck any functionality

export const HEADER_AUTHORIZATION = 'a64bccc5-1648-46ae-ad78-b0f890f1d6c1';

export const DEVSTAGE_USERPOOL_ID = 'us-west-2_R4zfKO1hy';
export const QASTAGE_USERPOOL_ID = 'us-west-2_R4zfKO1hy';
export const CLINICALUSER_STAGE_USERPOOL_ID = 'us-west-2_VU5TDSRwJ';

export const DEVSTAGE_USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const QASTAGE_USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const CLINICALUSER__USERPOOL_WEBCLIENT_ID = '34jp7c8060jmgqu9bnhjptrotc';

export const LOGIN = import('src/auth/login/Login');
export const ADMIN_LOGIN = import('src/auth/login/Login');

export const TRANSACTIONS = import(
  'src/modules/Transactions/ApplicationsTransactions'
);
export const ORGANIZATION = import('src/modules/Organization/Organization');

export const ADMIN_MANAGEMENT = import(
  'src/modules/AdminManagement/AdminManagement'
);

export const USER_MANAGEMENT = import(
  'src/modules/UserManagement/UserManagement'
);

export const SECRET_MANAGEMENT = import(
  'src/modules/SecretManagement/SecretManagement'
);

export const SSH_SERVER_MANAGEMENT = import(
  'src/modules/SSHServerManagement/SSHServerManagement'
);

export const ADMIN_OPERATOR_USER_ASSETS = import(
  'src/modules/UserManagement/UserManagement'
);
export const ADMIN_OPERATOR_CHAT_BOT = import('src/modules/ChatBot/ChatBot');
export const CHAT_BOT_CONNECT = import('src/modules/ChatBot/ChatBotConnect');
export const FILE_MANAGER_MODULE = import(
  'src/modules/FileManager/FileManager'
);

export const ERROR_PAGE = import('src/modules/ErrorPage/ErrorPage');
export const I_TERMINAL = import('src/modules/ITerminalWindow/ITerminalWindow');

export const COMPLETED = 'completed';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export enum statusLable {
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress'
}

export const PASSWORD_STRENGTH_LABEL_BY_SCORE: Record<number, string> = {
  0: 'weak',
  1: 'weak',
  2: 'fair',
  3: 'good',
  4: 'strong'
};

export const MEGABYTE_CONVERTER = 1000000;
export const MESSAGE = 'message';
export const STATUS_404 = '404';

export const CONNECTION_TYPE = [
  {
    id: 'pem-file',
    name: 'Pem file'
  },
  {
    id: 'username-password',
    name: 'Username & Password'
  }
];

export const accessTokenFunction = (token?: any) => {
  API_ACCESS_TOKEN = token;
};
export enum BotStatus {
  BOT_IN_PROGRESS = 'BOT_IN_PROGRESS',
  BOT_FAILED = 'BOT_FAILED',
  BOT_COMPLETED = 'BOT_COMPLETED'
}
