import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ChatBotAPIs from '../apis/ChatBotAPIs';
import { BotData } from '../models';
import { RootState } from 'src/store/reducer';
import { getStartBotTraning } from '../apis/ChatBotAPIs';

interface BotManagementState {
  botData: BotData[];
  loadingBotData: boolean;
  isBotListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  openAIKeyData: any;
  awsKeyData: any;
}

const initialState: BotManagementState = {
  botData: [],
  loadingBotData: false,
  isBotListUpdated: false,
  isError: false,
  errorMessage: '',
  openAIKeyData: [],
  awsKeyData: []
};
export const selectorBotState = ({ botManagementData }: RootState) => ({
  botData: botManagementData?.botData,
  loadingBotData: botManagementData?.loadingBotData,
  isBotListUpdated: botManagementData?.isBotListUpdated,
  isError: botManagementData?.isError,
  errorMessage: botManagementData?.errorMessage,
  openAIKeyData: botManagementData?.openAIKeyData,
  awsKeyData: botManagementData?.awsKeyData
});
export const getBotData = createAsyncThunk(
  'BotManagementData/getBotData',
  async (payload: object) => {
    const response = await ChatBotAPIs.getChatBotListAPI(payload);
    return response;
  }
);
export const getSecretOpenAIKey = createAsyncThunk(
  'BotManagementData/openAIKeyData',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await ChatBotAPIs.getSecretKeyAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSecretAWSKey = createAsyncThunk(
  'BotManagementData/awsKeyData',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await ChatBotAPIs.getSecretKeyAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addBotEvent = createAsyncThunk(
  'BotManagementData/addBotEvent',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await ChatBotAPIs.addBotAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteBot = createAsyncThunk(
  'BotManagementData/deleteBot',
  async (userEmail: any, { rejectWithValue }) => {
    try {
      const response = await ChatBotAPIs.deleteBotAPI(userEmail);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateBotEvent = createAsyncThunk(
  'BotManagementData/updateBotEvent',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await ChatBotAPIs.updateBotAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateBotStatusEvent = createAsyncThunk(
  'BotManagementData/updateBotStatusEvent',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await ChatBotAPIs.botStatusUpdate(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//  Start Training for Bot
export const startTrainingForBot = createAsyncThunk(
  'BotManagementData/startTrainingForBot',
  async (payload: any, { rejectWithValue }) => {
    try {
      return await getStartBotTraning(payload.orgId, payload.botId);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getAssetDTO = (masterList) => {
  return [
    ...masterList.map((entry) => {
      if (entry.id) {
        return entry;
      }
      return {
        id: entry.asset_id,
        name: entry.asset_name,
        description: entry.asset_description,
        status: entry?.status,
        secretId: entry?.secretId,
        promptTemplate: entry?.promptTemplate
      };
    })
  ];
};

export const botManagementSlice = createSlice({
  name: 'botManagementData',
  initialState,
  reducers: {
    setIsBotListUpdated: (state: any, action) => {
      state.isBotListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getBotData.pending, (state) => {
        state.loadingBotData = true;
      })
      .addCase(getBotData.fulfilled, (state: any, action) => {
        state.loadingBotData = false;
        if (action.payload) {
          const botData = getAssetDTO(action.payload);
          state.botData = botData;
          state.isBotListUpdated = false;
        }
      })
      .addCase(getSecretOpenAIKey.rejected, (state) => {
        state.loadingBotData = false;
      })
      .addCase(getSecretAWSKey.fulfilled, (state: any, action) => {
        if (action.payload) {
          state.awsKeyData = action.payload;
        }
      })
      .addCase(getSecretOpenAIKey.fulfilled, (state: any, action) => {
        if (action.payload) {
          state.openAIKeyData = action.payload;
        }
      })
      .addCase(addBotEvent.fulfilled, (state: any, action) => {
        if (action.payload) {
          state.isBotListUpdated = true;
        }
      })
      .addCase(deleteBot.fulfilled, (state: any, action) => {
        if (action.payload) {
          state.isBotListUpdated = true;
        }
      })
      .addCase(updateBotEvent.fulfilled, (state: any, action) => {
        if (action.payload) {
          state.isBotListUpdated = true;
        }
      })
      .addCase(updateBotStatusEvent.fulfilled, (state: any, action) => {
        if (action.payload) {
          state.isBotListUpdated = true;
        }
      })
      .addCase(startTrainingForBot.fulfilled, (state: any, action) => {
        if (action.payload) {
          state.isBotListUpdated = true;
        }
      });
  }
});

export const { setIsBotListUpdated, setIsError } = botManagementSlice.actions;
export default botManagementSlice.reducer;
